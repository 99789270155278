/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.ng-select .ng-select-container .ng-value-container .ng-input>input{
    &::placeholder{
        color: red;
    }
}
.custom-select{
    min-height: 38px !important;
	cursor: pointer;
    align-items: center;
    color: #303030;
    padding: 2px 10px;
    .ng-select-container{
        margin-top: 5px !important;
    }
    .ng-dropdown-panel-items{
        max-height: 200px !important;
        padding: 3px !important;
    }
    .ng-dropdown-panel-items::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    
    .ng-dropdown-panel-items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e0e0e0;
    border-radius: 10px;
    }
    
    .ng-dropdown-panel-items::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #303030;
    }
    .ng-dropdown-panel{
        background-color: #fff !important;
        border:none;
        border-radius: 6px;
        margin-top: 23px !important;
        margin-left: -14px !important;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    }
    .ng-option{
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
        padding-left: 5px !important;
        margin:2px 0px;
        border-bottom: 1px solid rgb(210, 210, 210);
        &.ng-option-selected {
            background-color: $primary !important;
            color: $white !important;
            &.ng-option-marked {
              background-color: $primary !important;
              color: $white !important;
            }
            .ng-option-label {
              font-weight: inherit !important;
            }
          }
          &.ng-option-marked {
            background-color: rgba($primary, 0.12) !important;
            color: $primary !important;
          }
          &.ng-option-disabled {
            color: $text-muted !important;
          }
    }
    &.error {
        .ng-select-container {
          border-color: $danger !important;
        }
    }
    &.ng-select-multiple {
    .ng-value {
        background-color: $primary !important;
        color: $white;
        border: none !important;
        font-size: 0.8rem !important;
        border-radius: 4px !important;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        margin: 5px;
        .ng-value-icon {
        &.right {
            border: 0 !important;
        }

        &.left {
            border: 0 !important;
        }

        &:hover {
            background-color: transparent !important;
        }
        }
        .ng-value-icon {
        &.left {
            font-size: 1.1rem !important;
        }
        &.right {
            font-size: 1.1rem !important;
        }
        }
    }
    }

    //   .ng-arrow {
    //     background-image: url('../icons/down-arrow-gray.svg');
    //     background-size: 12px 12px, 10px 10px;
    //     background-repeat: no-repeat;
    //     height: 0.8rem !important;
    //     padding-right: 1.5rem;
    //     margin-left: 0;
    //     margin-top: 0;
    //     left: 0;
    //     border-style: none !important;
    //   }
      &.ng-select-opened > .ng-select-container .ng-arrow {
        top: 0px !important;
      }
      .ng-clear-wrapper {
        height: 18px;
      }
}

.custom-select-multi{
    height: fit-content !important;
}

// .custom-readonly{
// 	 .form-control[readonly]{
// 		background-color: #F7F7F7 !important;
// 	 }
// }
// .custom-underLine{
//     .ng-option{
//         border-bottom: 1px solid rgb(210, 210, 210);
//     }
// }

.form-check-input:checked ~ .form-check-label::before {
    color: #fff;
    border-color: #7B1FA2;
    background-color: red;
}
.ng2-date-pickr{
    .form-control:disabled, .form-control[readonly] {
      background-color: #fff !important;
  }}
