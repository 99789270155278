@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import "../node_modules/font-awesome/scss/font-awesome.scss";

* {
    box-sizing: border-box;
    font-family: "Noto Sans JP", sans-serif !important;
    font-size: normal;
}

input {
    &:focus {
        border: 1px solid #303030 !important;
    }
}

.main-padding {
    padding: 80px 20px 20px 20px;
}

.btn-black {
    background-color: #303030;
    padding: 9px 23px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    height: 37px;
    width: 100%;
    border: none;
    white-space: nowrap;

    &:hover {
        background-color: #838383 !important;
        color: #FFFFFF;
    }
}

.btn-cancel {
    padding: 9px 23px;
    background: #F7F7F7;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #303030;
    border: none;
    white-space: nowrap;

    &:hover {
        background: #E5E5E5;
    }
}

.form-label {
    line-height: 16px;
    color: rgba(48, 48, 48, 0.45);
    font-weight: 500;
    font-size: 13px
}

.page-head {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #303030;
    white-space: nowrap;
    margin: 0 !important
}

.select-options {
    font-size: 14px;
    color: #303030;
    padding: 20px 0px !important;
}

.popup-inner {
    padding: 20px;
}

.popup-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    width: 100%;
    color: #303030;
}

.popup-body {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #7A7A7A;
    width: 100%
}

.btn-section {
    width: 100%;
    border-top: none;
    margin-top: 37px;
}

.btn-width {
    width: 167px;
    height: 37px;
    margin: 10px
}

.back-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(48, 48, 48, 0.45);
}

.label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #303030;
}

.modal-titles {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #303030;
}

.form-validation-style {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FF6262;
}

.pac-container {
    z-index: 9000 !important
}

body {
    background: #E5E5E5 !important;
    height: 100%;
}

.pagination-div {
    background: rgba(48, 48, 48, 0.05);
    border-radius: 16px;
    height: 30px;
    text-align: center;
    padding: 0px 10px;
}

.paginataion-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba(48, 48, 48, 0.45);
    padding: 5px 0px;
    cursor: pointer;
}

.pagination-btn-selected {
    background: #303030;
    border-radius: 29px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px;
    cursor: pointer;
}

.pagination-count {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(48, 48, 48, 0.45);
}

th {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #303030;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #7B1FA2;
    background-color: red;
}

input {
    color: #303030;
}

label {
    color: #303030;
}

.form-label {
    color: #303030
}

.form-control {
    color: #303030
}

.no-data {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #303030;
    padding-top: 50px;
}

th {
    white-space: nowrap;
}

li {
    .custom-icon-inactive {
        display: block;
        margin-right: 5px;
    }

    .custom-icon-active {
        display: none;
        margin-right: 5px;
    }
}

.active {
    .custom-icon-inactive {
        display: none;
    }

    .custom-icon-active {
        display: block;
    }
}

.a-class {
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
        opacity: 0.5;
    }
}

.a-name-class {
    cursor: pointer;
    text-decoration: underline;
    
    &:hover {
        opacity: 0.6;
        text-decoration: none;
    }
}

.email-class {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #808080;
}

.carousel-control-prev-icon {
    background-color: rgb(0, 0, 0, 0.5) !important;
    border-radius: 20px;
    height: 25px;
    width: 25px;
}

.carousel-control-next-icon {
    background-color: rgb(0, 0, 0, 0.5) !important;
    border-radius: 20px;
    height: 25px;
    width: 25px;
}

.carousel-indicators li {
    height: 11px;
    width: 11px;
    border-top: none;
    border-bottom: none;
    border-radius: 10px !important;
}

.angular-editor-toolbar-set button i {
    font-family: FontAwesome !important;
    color: #474d6d !important;
}

.angular-editor-toolbar {
    font: 100 14px/15px sans-serif, Arial !important;
    background-color: #fff !important;
    padding: 0;
    border: none !important;
    border-bottom: 1px solid rgba(48, 48, 48, 0.1) !important;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
    background-color: none !important;
    border: none !important;
    padding: 0;
    font-family: sans-serif !important;
    font-size: 16px;
    width: 0;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
    min-height: 5rem;
    padding: .5rem 0.8rem 1rem;
    border: none !important;
    border-bottom: 1px solid rgba(48, 48, 48, 0.1) !important;
}

.date-picker-readonly {
    .form-control[readonly] {
        background-color: #F7F7F7 !important;
        border: none;
    }
}



.search-input {
    border-radius: 0.357rem 0 0 0.357rem !important;
    width: 230px;
    height: 38px ;
    &:focus {
        border:  1px solid #d8d6de !important;
        box-shadow: none !important;
    }
}

.search-btn {
    right: 0;
    height: 38px;
    border-radius: 0 0.357rem 0.357rem 0;
    background: #F7F7F7;
    border: 1px solid #d8d6de;
    width: 38px;
    padding-bottom: 5px;
}
@media print {
    body * {
      visibility: hidden;
    }
    #invoiceDiv * {
      visibility: visible;
    }
    #section-to-print {
      position: absolute;
      left: 0;
      top: 0;
    }
  }